export default defineNuxtRouteMiddleware((to) => {
  const {
    public: { baseUrl, auth0Config },
  } = useRuntimeConfig();
  const returnTo = encodeURI(baseUrl + "/auth/logout/success");
  if (to.path === "/auth/logout") {
    return navigateTo(
      `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&federated&returnTo=${returnTo}`,
      { external: true },
    );
  }
});
